const downloadBasketAddBtn = document.querySelectorAll('.btn-basket-add');
const downloadBasketDownloadBtn = document.querySelectorAll('.btn-basket-download');
const downloadBasketFileList = document.querySelector('.basket-file-list');

function downloadBasketRefresh()
{
    if (downloadBasketFileList === null) {
        return;
    }
    downloadBasketFileList.innerHTML = '';
    downloadBasketAddBtn?.forEach(function (button) {
        button.classList.remove('basket-has-items');
        button.removeAttribute('disabled');
    });
    if (localStorage.getItem('basket')) {
        let basket = JSON.parse(localStorage.getItem('basket')) || [];
        basket.forEach(function (item) {
            const li = document.createElement('li');
            li.textContent = item.file;
            downloadBasketFileList.appendChild(li);
            document.querySelector(`[data-doc="${item.doc}"]`).classList.add('basket-has-items');
            document.querySelector(`[data-doc="${item.doc}"]`).setAttribute('disabled', true);
        });
    }
}
downloadBasketAddBtn?.forEach(function (button) {
    button.addEventListener('click', function (event) {
        event.preventDefault();
        const doc = event.target?.dataset.doc || null;
        const item = event.target?.dataset.item || '--';
        const file = event.target?.dataset.file || '--';
        const basket = JSON.parse(localStorage.getItem('basket')) || [];
        basket.push({"doc": doc, "item": item, "file": file});
        localStorage.setItem('basket', JSON.stringify(basket));
        const li = document.createElement('li');
        li.textContent = file;
        downloadBasketFileList.appendChild(li);
        event.target?.classList.add('basket-has-items');
        event.target.setAttribute('disabled', true);
    });
});

downloadBasketDownloadBtn?.forEach(function (button) {
    button.addEventListener('click', async function (event) {
        event.preventDefault();
        const format = event.target?.dataset.format || 'zip';
        const basket = JSON.parse(localStorage.getItem('basket')) || [];
        if (basket.length === 0) {
            return;
        }
        console.log(basket);
        const data = {};
        data.documents = basket;
        data.format = format;

        const response = await fetch('/api/v1/download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }
        const filename = response.headers.get('Content-Disposition').split('=')[1];
        const blob = await response.blob();

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename.replaceAll('"', '');
        link.click();
        localStorage.removeItem('basket');
        downloadBasketRefresh();
    });
});

downloadBasketRefresh();
